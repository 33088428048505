export default class  AccountingPayments {

    constructor() {

        this.dropify = null;

        this.bindEvents();
    }

    bindEvents() {

        $(".btn-close").off("click").click(function() {
            var button = $(this);
            var ajaxData = {
              url: $(button).data("href"),
              method: "PUT"
            };

            $.ajax(ajaxData).done(function(json) {
                var id = button.data("itemId");
                button.remove();

                $(".badge-"+id).removeClass("badge-warning").removeClass("badge-danger").removeClass("badge-success").addClass("badge-primary").html("Paiement reçu");
            });

        });

        $('#validation_modal').on('show.bs.modal', function (event) {
            let button = $(event.relatedTarget)
            let id = button.data('item-id')
            let name = button.data('name')
            let amount = button.data('amount')
            let action = button.data('href')

            $("#customer_name").text(name);
            $("#customer_payment_amount").text(amount);
            $("#back_url").val(window.location.href);
            $("#validation_form").attr("action", action);
            $("#payment_amount_received").val(parseFloat(amount));

            console.log("amount -> "+amount)
;        })

        $("#city_modal").on('show.bs.modal', function (event) {
            let button = $(event.relatedTarget)
            let id = button.data('item-id')
            let name = button.data('name')
            let amount = button.data('amount')
            let city_id = button.data('city_id')
            let action = button.data('href')

            $("#city_customer_payment_amount").text(amount);
            if(city_id!=null) {
                $("#set_city_id").val(city_id);
            }
            $("#city_back_url").val(window.location.href);
            $("#city_selection_form").attr("action", action);
        });

        $(".btn-edit-fees").off("click").click(function() {
            var fees = $(this).data("fees");
            $("#edit_fees_modal").modal();

            $("#edit_fees_form").attr("action", $(this).data("href"));
            $("#edit_fees_form").find('input[name="fees"]').val(fees);
        });

        $(".btn-refund").off("click").click(function() {
            var amountRefunded = $(this).data("amount_refunded");
            $("#refund_modal").modal();

            $("#refund_form").attr("action", $(this).data("href"));
            $("#refund_form").find('input[name="amount_refunded"]').val(fees);
        });

        $("#refund_modal").on('show.bs.modal', function (event) {

            console.log("hey");
            $("#refund_form").attr("action", $(event.relatedTarget).data("href"));
            $("#refund_form").find('input[name="amount_refunded"]').val($(event.relatedTarget).data("amount_refunded"));

        });

    }

    onDestroy() {
        
    }

}