export default class DrivingTestsPartnersRequests {

    constructor() {

        this.bindEvents();

    }

    bindEvents() {
        $(".open-cancelled-comment-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#cancelled_comment_form").attr("action", actionURL);
            $("#cancelled_comment").val($(this).data("content"));
            $("#cancelled_comment_modal").modal();
        });

    }
}