export default class FormulasIndex {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        $('#partner_description_modal').off("show.bs.modal").on('show.bs.modal', function (event) {
            var target = $(event.relatedTarget);

            var modal = $(this);
            modal.find('.modal-title').html(target.data("title"));
            modal.find('.modal-body').html(target.data("content"));
        });
    }

    onDataChanged(data) {
    }

}