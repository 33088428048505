export default class  AccountingIndex {

  constructor() {

    this.data = null;

    this.bindEvents();

    window.application.setOnDataChangeListener(this);
  }

  bindEvents() {
    /*$(".btn-turnover-period").off("click").on("click", function(e) {
      if($(this).data("period")=="year") {
        $("#btn_turnover_period_year").removeClass("btn-light").addClass("btn-primary");
        $("#btn_turnover_period_month").removeClass("btn-primary").addClass("btn-light")
        $("#year_data").removeClass("d-none");
        $("#month_data").addClass("d-none");
      }
      else {
        $("#btn_turnover_period_month").removeClass("btn-light").addClass("btn-primary");
        $("#btn_turnover_period_year").removeClass("btn-primary").addClass("btn-light")
        $("#month_data").removeClass("d-none");
        $("#year_data").addClass("d-none");
      }
    });*/
  }

  onDataChanged(data) {
    console.log(data);
    if(data!=null) {
      this.data = data;
    }
  }

}