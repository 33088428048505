export default class DrivingTestsResults {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);

        this.selectedDrivingTest = null;
        this.timeoutID = null;
    }


    bindEvents() {
        $(".btn-repassing-waiting-time").off("click").click(function(e) {
            e.preventDefault();

            $("#repassing_waiting_time_form").attr("action", $(this).data("url"));
            $("#repassing_waiting_time").val($(this).data("value"));
            $("#repassing_waiting_time_modal").modal();
        });

        $("#instructor_type").select2({
            placeholder: "Type de moniteur",
            multiple: true,
            closeOnSelect: false,
            allowClear: true,
        });
    }
}