export default class InitialAssessmentQuestionsForm {

    constructor() {
        this.selectCategory = null;
        this.selectType = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.selectCategory = $('#initial_assessment_form_question_initial_assessment_form_category_id').selectpicker();
        this.selectType = $('#initial_assessment_form_question_question_type').selectpicker();

        this.initMedia()
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.selectCategory.selectpicker('destroy');
        this.selectType.selectpicker('destroy');
    }

    initMedia() {
        this.dropify_upload = $('#initial_assessment_form_question_media').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });
    }
}