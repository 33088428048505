export default class SessionsForm {

    constructor() {
        this.formula_select = null;
        this.range_picker = null;
        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.formula_select = $('#session_formula_id').selectpicker();
    }

    onDataChanged(data) {
        if(data.starts_at !== ""){
            this.range_picker = $('input[id="session_range"]').daterangepicker({
                drops: 'up',
                startDate: new Date(data.starts_at),
                endDate: new Date(data.ends_at)
            });
        }else{
            this.range_picker = $('input[id="session_range"]').daterangepicker({
                drops: 'up'
            });
        }
        this.range_picker.on("apply.daterangepicker", this.onRangeSelected)
    }

    onDestroy() {
        this.formula_select.selectpicker('destroy');
    }

    onRangeSelected(ev, picker){
        $("#session_starts_at").val(picker.startDate);
        $("#session_ends_at").val(picker.endDate);
    }
}