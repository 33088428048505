import DefaultDataTable from "../utils/DefaultDataTable";

export default class InstructorCandidatesIndex {

    constructor() {
        this.dataTable = null
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        var self = this;

        $("#search_city").change(function(){
            self.candidatesDataTable.draw();
            self.favoritesDataTable.draw();
        });

        $("#search_text").keyup(function(){
            clearTimeout(self.timeOut);
            self.timeOut = setTimeout(function (){
                self.candidatesDataTable.draw();
                self.favoritesDataTable.draw();
            }, 300);
        });

        this.candidatesDataTable = $('#candidates-ajax-datatable').DataTable({
            "responsive": true,
            searchDelay: 350,
            "order": [[ 0, "desc" ]],
            "columnDefs": [{
                "targets": -1,
                "orderable": false
            }],
            "serverSide": true,
            "ajax": {
                url: "/instructor_candidates/ajax_index",
                'data': function(data){
                    data.search["value"] = $("#search_text").val();
                    data.search_city = $("#search_city option:checked").val();
                }
            },
            "columns": [
                { "data": "id" },
                { "data": "created_at", render: function(data, type,row) {
                    return moment(data).format("DD/MM/YYYY");
                }  },
                { "data": "city", "orderable": false },
                { "data": "first_name" },
                { "data": "last_name" },
                { "data": "email", "orderable": false  },
                { "data": "phone_number", "orderable": false },
                { "data": "validation_status", "orderable": false  },
                { "data": "actions" }
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            "dom": "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });

        this.candidatesDataTable.on( 'draw', function () {
            $('[data-toggle="tooltip"]').tooltip();
            self.bindFavorites()
            self.bindStatusModal();
        } );

       this.favoritesDataTable = $('#favorites-ajax-datatable').DataTable({
            "responsive": true,
            searchDelay: 350,
            "order": [[ 0, "desc" ]],
            "columnDefs": [{
                "targets": -1,
                "orderable": false
            }],
            "serverSide": true,
            "ajax": {
                url: "/instructor_candidates/ajax_index",
                'data': function(data){
                    data.favorites_only = true;
                    data.search["value"] = $("#search_text").val();
                    data.search_city = $("#search_city option:checked").val();
                }
            },
            "columns": [
                { "data": "id" },
                { "data": "created_at", render: function(data, type,row) {
                    return moment(data).format("DD/MM/YYYY");
                }  },
                { "data": "city", "orderable": false },
                { "data": "first_name" },
                { "data": "last_name" },
                { "data": "email", "orderable": false  },
                { "data": "phone_number", "orderable": false },
                { "data": "validation_status", "orderable": false  },
                { "data": "actions" }
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "dom": "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });

        this.favoritesDataTable.on( 'draw', function () {
            $('[data-toggle="tooltip"]').tooltip();
            self.bindFavorites()
            self.bindStatusModal();
        } );
    }

    bindFavorites() {
        $(".is-favorite-btn").off("click").on("click", function(e) {
            var btn = this;
            e.preventDefault();
            $.ajax({
                method: "PUT",
                url: "/instructor_candidates/"+$(btn).data("candidateId")+"/favorite",
                 success: function(json){
                    if(json.is_favorite) {
                        $(btn).find("i").removeClass("mdi-star-outline").addClass("text-warning mdi-star");
                    }
                    else {
                        if($('#favorites-ajax-datatable').length==1) {
                            $(btn).parent().parent().remove();
                        }
                        else {
                            $(btn).find("i").removeClass("text-warning mdi-star").addClass("mdi-star-outline");
                        }
                    }
                 }
            })
        });
    }

    bindStatusModal() {
        var self = this;
        $(document).on('ajax:success', 'a.candidate-validation-status-btn[data-remote=true][data-method=put]', function (e, data, status, xhr) {
            self.candidatesDataTable.ajax.reload();
            self.favoritesDataTable.ajax.reload();
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
        if(this.dataTable !== null){
            this.dataTable.destroy();
        }
        this.dataTable = null;
    }
}