export default class DefaultDataTable {

    constructor(opts={}) {
        this.dataTable = null;
        this.opts = opts;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.dataTable = $('#basic-datatable').DataTable(Object.assign({},{
            "responsive": true,
            "columnDefs": [{
                "targets": -1,
                "orderable": false
            }],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        }, this.opts));
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
    }

}