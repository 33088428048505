import ConfigRoutes from "./Routes";
import ExtraDateFuncitions from "../utils/ExtraDateFuncitions";
import ExtraJqValidationFunctions from "../utils/ExtraJqValidationFunctions";
import * as Sentry from "@sentry/browser";

export default class Application {

    constructor() {
        this.routes = new ConfigRoutes();
        this.controller_name = null;
        this.action_name = null;
        this.current_handler = null;
        this._dataChangedListener = null;
        this.env = process.env.RAILS_ENV;
        this.initSentry();
        this.extraDateFunctions = new ExtraDateFuncitions();
        this.extraJqValidationFunctions = new ExtraJqValidationFunctions();

        this.addJSFunctions();

        this.bindEvents();
    }


    bindEvents() {
        let self = this;
        $(document).off("turbolinks:before-cache").on("turbolinks:before-cache", function(e) {
            $(document).off("Application::PageChanged");
            if (self.current_handler != null && self.current_handler.onDestroy !== undefined){
                self.current_handler.onDestroy();

                if(window.tinymce!=undefined) {
                    tinymce.remove();
                }
            }
        });
    }

    setController(controller) {
        this.controller_name = controller;
    }

    setAction(action) {
        this.action_name = action;
    }

    setContext(context) {
        this.context = context;
    }

    setData(data) {
        this.data = data;
        if(this._dataChangedListener!=null) {
            this._dataChangedListener.onDataChanged(data);
        }
    }

    setOnDataChangeListener(listener) {
        this._dataChangedListener = listener;
    }

    getMeta(name){
        return $("meta[name="+name+"]").attr("content")
    }

    pageChanged() {
        this._dataChangedListener = null;

        // if (this.current_handler != null && this.current_handler.onDestroy !== undefined){
        //     this.current_handler.onDestroy();
        // }

        this.current_handler = this.routes.getController(this.camelize(this.controller_name), this.camelize(this.action_name));
        this.setData(window.data);
        this.bindEventsAfterPageChanged();
    }

    bindEventsAfterPageChanged() {
        let self = this;
    }

    camelize(string) {
        let string_camelized =  string.replace(/([-_/][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '')
                .replace('/', '');
        });
        return this.capitalize(string_camelized)
    };

    capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    addData(object){
        if(window.data === undefined || window.data === null){
            window.data = {};
        }
        window.data= Object.assign(window.data, object)
    }

    addJSFunctions(){
        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }
    }

    initSentry() {
        Sentry.init({
            environment: this.env,
            dsn: "https://7bf01fd9f66c434f9d184068c43c2e5b@s1.sentry.isee-u.fr/5",
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            replaysSessionSampleRate: this.sentryRate(),
            replaysOnErrorSampleRate: this.sentryErrorRate(),
            ignoreErrors: [
                // Random plugins/extensions
                "top.GLOBALS",
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                "originalCreateNotification",
                "canvas.contentDocument",
                "MyApp_RemoveAllHighlights",
                "http://tt.epicplay.com",
                "Can't find variable: ZiteReader",
                "jigsaw is not defined",
                "ComboSearch is not defined",
                "http://loading.retry.widdit.com/",
                "atomicFindClose",
                // Facebook borked
                "fb_xd_fragment",
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                // reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268
                "bmi_SafeAddOnload",
                "EBCallBackMessageReceived",
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                "conduitPage",
            ],
            denyUrls: [
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                // Microsoft Clarity
                /clarity\.js/i,
                // The clarity url has a random char at the beginning as sub domain
                /[a-z0-9]+\.clarity\.ms/i,
                /cdn\.segment\.com/i,
            ],
            allowUrls: [/https?:\/\/((bo|eleves|moniteurs|autoecoles)\.)?lanavette\.co/],
        });

        this.setSentryUser();
    }

    sentryRate() {
        const envRates = {
            'production': 0.0,
            'staging': 0.0,
            'staging2': 0.0
        };
        return envRates[this.env] || 0.0;
    }

    sentryErrorRate() {
        const envErrorRates = {
            'production': 0.3,
            'staging': 1.0,
            'staging2': 1.0
        };
        return envErrorRates[this.env] || 0.0;
    }

    setSentryUser() {
        let user = {
            username: `${this.getMeta("domain")}-anonymous-${Math.random().toString(36).substring(7)}`
        };

        if (this.getMeta("user_id") !== '0') {
            user = {
                id: this.getMeta("user_id"),
                email: this.getMeta("user_email"),
                username: `${this.getMeta("domain")}-${this.getMeta("user_id")}-${this.getMeta("user_email")}`
            };
        }

        Sentry.setUser(user);
    }

}