export default class CalendarEntry {

  constructor(arg) {
    this.arg = arg;
    this.rootNode = [];
  }

  render() {
    //console.log(this.arg);
    let row = document.createElement("div");
    if(this.arg.view.type=="resourceTimeline") {
      row.className = "row h-100 pl-2 pr-2";
    }
    else {
      row.className = "row h-100";
    }

    let col1 = document.createElement("div");
    col1.className = "col-3 p-0 pt-1 text-center";
    col1.style["border-top-left-radius"] = "3px";
    col1.style["border-bottom-left-radius"] = "3px";


    let col2 = document.createElement("div");
    col2.className = "col-9";

    let avatar1 = document.createElement("div");
    avatar1.className = "mx-auto"

    let avatar1Title = document.createElement("div");

    let title = document.createElement("div");
    let customerName = document.createElement("div");
    let sessionsPart = document.createElement("div");
    let description = document.createElement("div");
    let icon = document.createElement('i');

    title.className = "mt-1";

    let topRightPart = document.createElement("div")
    topRightPart.className = "float-right";

    var slotCount = 0; 

    avatar1.appendChild(avatar1Title);
    avatar1Title.appendChild(icon);

    if(this.arg.event.extendedProps.special_event_type!="special_event_type_unspecified") {
      this.arg.backgroundColor = this.lightenOrDarkenColor(this.arg.backgroundColor, -50);
    }

    if(this.arg.view.type!="listWeek") {
      if(this.arg.event.extendedProps.special_event_type=="special_event_type_unspecified") {
        col1.style.backgroundColor = this.lightenOrDarkenColor(this.arg.backgroundColor, -20);
      }
      else {
        col1.style.backgroundColor = this.arg.backgroundColor;
      }
    }

    if (this.arg.event.extendedProps.event_type=="type_lunch") {
      icon.className = 'mdi mdi-food-fork-drink';
    } 
    else if(this.arg.event.extendedProps.event_type=="type_not_assigned") {
      icon.className = 'mdi mdi-clock-outline';
    }   
    else if(this.arg.event.extendedProps.event_type=="type_other") {
      icon.className = 'mdi mdi-car';
    } 
    else if(this.arg.event.extendedProps.event_type=="type_office") {
      icon.className = 'mdi mdi-office-building';
    } 
    else if(this.arg.event.extendedProps.event_type=="type_wash") {
      icon.className = 'mdi mdi-car-wash';
    }   
    else if(this.arg.event.extendedProps.event_type=="type_holiday") {
      icon.className = 'mdi mdi-beach';
    } 
    else if (this.arg.event.extendedProps.event_type=="type_driving_lesson") {
      icon.className = 'mdi mdi-account';
      slotCount = 1;
    }
    else if (this.arg.event.extendedProps.event_type=="type_driving_lesson_duo") {
      icon.className = 'mdi mdi-account-multiple';
      slotCount = 2;
    }
    else if(this.arg.event.extendedProps.event_type=="type_pack_hour") {
      icon.className = 'mdi mdi-account';
    }
    else if(this.arg.event.extendedProps.event_type=="type_theoric_lesson") {
      icon.className = 'mdi mdi-account-school';
    }
    else if(this.arg.event.extendedProps.event_type=="type_free_driving_lesson") {
      icon.className = 'mdi mdi-account';
    }
    else if(this.arg.event.extendedProps.event_type=="type_external_driving_lesson") {
      icon.className = 'mdi mdi-account';
    }

    icon.className += " fs-14";

    col1.appendChild(avatar1);


    let transmissionType = document.createElement("div");
    if(this.arg.event.extendedProps.driving_transmission_type!=null) {
      transmissionType.className = "fs-9";
      transmissionType.innerHTML = I18n.t("activerecord.attributes.driving_instructor.driving_transmission_types_alt."+this.arg.event.extendedProps.driving_transmission_type);
      col1.appendChild(transmissionType);
    }

    var start = moment(this.arg.event.start);
    var end = moment(this.arg.event.end);

    var duration = moment.duration(end.diff(start));
    var hours = duration.hours();
    var minutes = duration.minutes();
    let durationPart = document.createElement("span");
    var charactersPerHour = 64;
    var charactersPerHalfHour = 32;


    durationPart.className = "fs-9";

    var durationStr = "";
    if(hours > 0) {
      durationStr = hours+"h";
      if(minutes>0) {
        durationStr += minutes+"m";
      }
    }
    else {
      durationStr = minutes+"m"
    }

    if(hours==0) {
       durationPart.innerHTML = "&nbsp;&nbsp;"+durationStr;
      avatar1Title.appendChild(durationPart);
    }
    else {
      durationPart.innerHTML = durationStr;
      col1.appendChild(durationPart);
    }


    // if (this.arg.event.extendedProps.is_editable==true) {
    //   let iconEditable = document.createElement('i')
    //   iconEditable.className = 'fas fa-pencil-ruler';

    //   title.appendChild(iconEditable);
    // }

    

    row.appendChild(col1);
    row.appendChild(col2);

    if(this.arg.event.extendedProps.uid==null) {
      let slotIcon1 = document.createElement("i")
      slotIcon1.className = "mdi mdi-checkbox-blank-outline";

      let slotIcon2 = document.createElement("i")
      slotIcon2.className = "mdi mdi-checkbox-blank-outline";

      if(slotCount==1) {
        topRightPart.appendChild(slotIcon1);
        if(this.arg.event.extendedProps.affectedSessionIds.length>0) {
          slotIcon1.className = "mdi mdi-checkbox-marked-outline";
        }
      }
      else if(slotCount==2) {
        topRightPart.appendChild(slotIcon1);
        topRightPart.appendChild(slotIcon2);

        if(this.arg.event.extendedProps.affectedSessionIds.length>1) {
          slotIcon1.className = "mdi mdi-checkbox-marked-outline";
          slotIcon2.className = "mdi mdi-checkbox-marked-outline";
        }else if(this.arg.event.extendedProps.affectedSessionIds.length>0) {
          if(this.arg.event.extendedProps.affectedSessionIds[0]==this.arg.event.extendedProps.sessionIds[0]) {
            slotIcon1.className = "mdi mdi-checkbox-marked-outline";
          }
          else {
            slotIcon2.className = "mdi mdi-checkbox-marked-outline";
          }
        }
      }

      slotIcon1.className += " fs-14";
      slotIcon2.className += " fs-14";
    }

    //console.log(slotCount);
    //console.log(this.arg.event.extendedProps);

    if(this.arg.event.extendedProps.customerIds!=null && this.arg.event.extendedProps.customerIds.length>0) {
      sessionsPart.innerHTML = this.getEventCustomerNames(this.arg.event).join(" et ");
      charactersPerHour = 40;
      charactersPerHalfHour = 30;
    }
    else if(this.arg.event.extendedProps.sessionIds!=null && this.arg.event.extendedProps.sessionIds.length>0) {
      sessionsPart.innerHTML = (
        (this.arg.event.extendedProps.sessionIds.length>1 ? "Sessions" : "Session")+" "+
        this.arg.event.extendedProps.sessionIds.join(" et ")
      )
      charactersPerHour = 40;
      charactersPerHalfHour = 30;
    } /* session_ids -> not saved calendar entry from planification */
    else if(this.arg.event.extendedProps.session_ids!=null && this.arg.event.extendedProps.session_ids.length>0) {
      sessionsPart.innerHTML = (
        (this.arg.event.extendedProps.session_ids.length>1 ? "Sessions" : "Session")+" "+
        this.arg.event.extendedProps.session_ids.join(" et ")
      )
      charactersPerHour = 40;
      charactersPerHalfHour = 30;
    }


    title.innerHTML += " "+this.arg.event.title;

    var _desc = "";
    if(this.arg.event.extendedProps.special_event_type!=undefined) {
      _desc += this.arg.event.extendedProps.special_event_type!="special_event_type_unspecified" ?  "⚠️ "+I18n.t("calendar_entry_special_event_types."+this.arg.event.extendedProps.special_event_type) : "";
    }
    _desc += this.arg.event.extendedProps.driving_test_description!=null ? this.arg.event.extendedProps.driving_test_description.replace("\n", "<br/>")+"<br/>" : "";
    _desc += this.arg.event.extendedProps.description!=null ? this.arg.event.extendedProps.description.replace("\n", "<br/>") : "";

    console.log("_desc.length -> "+_desc.length);
    if(_desc.length>(hours*charactersPerHour + ((minutes==30) ? charactersPerHalfHour : 0))) {
      console.log("cut");
      _desc = _desc.replace("<br/>", " ");
      _desc = _desc.substring(0, (hours*charactersPerHour + ((minutes==30) ? charactersPerHalfHour : 0))-3)+"...";
    }
    else {
      console.log("NOT CUT");
    }

    console.log("_desc -> "+_desc);
    console.log("limit -> "+(hours*charactersPerHour));

    description.innerHTML += _desc;

    col2.appendChild(topRightPart);
    col2.appendChild(title);
    col2.appendChild(sessionsPart);
    col2.appendChild(description);

    this.rootNode = [row];

    return this;
  }

  getRootNode() {
    return { domNodes: this.rootNode }
  }


  getEventCustomerNames(event) {
    var names = [];

    $.each(event.extendedProps.customers, function(idx, customer) {
      names.push(customer.text);
    });

    return names;
  }

  lightenOrDarkenColor(col,amt) {
    var usePound = false;
    if ( col[0] == "#" ) {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if ( r > 255 ) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if ( b > 255 ) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if ( g > 255 ) g = 255;
    else if  ( g < 0 ) g = 0;

    var col2 = (g | (b << 8) | (r << 16)).toString(16);
    
    if(col2.length<6) {
      col2 = "0"+col2;
    }

    return (usePound?"#":"") + col2
  }
}