export default class ProspectsIndex {

    constructor() {
        this.dataTable = null;
        this.prospect_type = null;
        this.prospect_formula = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;

        $("#graph_clients").knob();
        $("#graph_contact").knob();
        $("#graph_code").knob();
        $("#graph_driving").knob();

        var buttons = [];


        if($('meta[name="role-id"]').attr("content")=="1") {

            buttons =  [
                {
                    extend: 'csvHtml5',
                    text: 'Export CSV',
                    className: "btn-primary",
                    exportOptions: {
                        //columns: "thead th:not(.ignore-export)",
                        columns: [ 3, 4, 5, 6, 7, 8 ],
                        format: {
                          body: function(html, row, col, node) {
                            return html.replace("<br/>", "\n").replace("<br>", "\n").replace(/<(?:.|\n)*?>/gm, '');
                          }
                        },
                        modifier: {search: 'applied', order: 'applied'}
                    }
                }
            ];
        }

        this.dataTable = $('#ajax-datatable').DataTable({
            "responsive": true,
            searchDelay: 350,
            pageLength: 50,
            /*dom: 'Bfrtip',*/
            buttons: buttons,
            "order": [[ 0, "desc" ]],
            "serverSide": true,
            "columnDefs": [{
                "targets": -1,
                "orderable": false
            },
            { "targets": 0, orderSequence: ['desc', 'asc'] },
            { "targets": 1,"orderable": false },
            { "targets": 2,"orderable": false },
            { "targets": 3,"orderable": false },
            { "targets": 4,"orderable": false },
            { "targets": 5,"orderable": false },
            { "targets": 6,"orderable": false },
            { "targets": 7,"orderable": false },
            { "targets": 8,"orderable": false },
             { "targets": 9,"orderable": false },
            { "targets": 10, orderSequence: ['desc', 'asc']},
            { "targets": 11,"orderable": false },
            { "targets": 12,"orderable": false },
            { "targets": 13,"orderable": false }
            ],
            "ajax": {
                'url':"prospects/ajax_index",
                'data': function(data){
                    data.prospect_purchase_funnel_paiement = $("#prospect_purchase_funnel_paiement").is(":checked");
                    data.prospect_assigned = $("#prospect_assigned option:checked").val();
                    data.prospect_formula = $("#prospect_formula option:checked").val();
                    data.prospect_updated_at = $("#prospect_updated_at").val();
                    data.only_motivated = $("#prospect_motivation_level_high").is(":checked");
                    data.only_need_callback = $("#prospect_motivation_level_need_callback").is(":checked");
                    data.city_id = $("#city_id").val();
                    data.search["value"] = $("#prospect_search").val();
                }
            },
            "columns": [
                { "data": "id" },
                { "data": "motivation_level" },
                { "data": "last_name" },
                { "data": "first_name" },
                { "data": "email" },
                { "data": "phone" },
                { "data": "departure_place" },
                { "data": "formula_and_history" },
                { "data": "purchase_funnel" },
                { "data": "assigned_to" },
                { "data": "last_activity_at" },
                { "data": "called_back_actions" },
                { "data": "called_back_by" },
                { "data": "actions" },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "dom": "B<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });

        this.dataTable.buttons().container().appendTo('#ajax-datatable_wrapper  .col-md-6:eq(0)');

        $('#prospect_assigned').change(function(){self.dataTable.draw();});
        $('#prospect_formula').change(function(){self.dataTable.draw();});
        $('#city_id').change(function(){self.dataTable.draw();});
        $('#prospect_motivation_level_high').change(function(){self.dataTable.draw();});
        $('#prospect_motivation_level_need_callback').change(function(){self.dataTable.draw();});
        $('#prospect_purchase_funnel_paiement').change(function(){self.dataTable.draw();});
        $('#prospect_updated_at').change(function(){self.dataTable.draw();});
        $('#prospect_search').keyup(function(){self.dataTable.draw();});

        this.prospect_type = $('#prospect_type').selectpicker();
        this.prospect_formula = $('#prospect_formula').selectpicker();

        this.dataTable.on( 'draw', function () {
             self.checkCalledbackVisibility();
             self.bindAfterRefresh();
            $('[data-toggle="tooltip"]').tooltip();
        });

        this.checkCalledbackVisibility();
    }

    checkCalledbackVisibility() {

        this.dataTable.column(5).visible(false);

        console.log("checkCalledbackVisibility");

        var calledbackActions = this.dataTable.column(11);
        var calledbackBy = this.dataTable.column(12);

        calledbackActions.visible($('#prospect_motivation_level_high').prop('checked'));
        calledbackBy.visible($('#prospect_motivation_level_high').prop('checked'));
    }

    bindAfterRefresh() {
        var self = this;
         $(".open-customer-session-search").off("click").click(function(e) {
            $("#customer_session_search_modal").find(".modal-title").html("Recherches de stages de "+$(this).data("full_name"))
            $("#customer_session_search_modal").modal();
            $("#customer_session_search_content").html("").load($(this).data("result_url"));
        });

         $(".motivation-level-badge").off("click").click(function(e) {
            $("#update_motivation_level_modal").find(".modal-title").html("Modifier le niveau de motivation de "+$(this).data("full_name")) 

            // var prospectID = $(this).data("prospect_id");
            // $("#motivation_level_0_form").attr("action", $("#motivation_level_0_form").data("url").replace("_ID_", prospectID+"")).on('ajax:success', function(e, data, status, xhr){
            //     $("#update_motivation_level_modal").modal("hide");
            //     self.dataTable.draw(false);
            // });
            // $("#motivation_level_1_form").attr("action", $("#motivation_level_1_form").data("url").replace("_ID_", prospectID+"")).on('ajax:success', function(e, data, status, xhr){
            //     $("#update_motivation_level_modal").modal("hide");
            //     self.dataTable.draw(false);
            // });
            // $("#motivation_level_2_form").attr("action", $("#motivation_level_2_form").data("url").replace("_ID_", prospectID+"")).on('ajax:success', function(e, data, status, xhr){
            //     $("#update_motivation_level_modal").modal("hide");
            //     self.dataTable.draw(false);
            // });

            $("#update_motivation_level_modal").modal();
            $("#update_motivation_level_content").html("").load($(this).data("result_url"), function() {
                $("#modal_lanavette_form").off("submit").submit(function(e) {
                    e.preventDefault();
                    $.ajax({
                      url: $(this).attr('action'),
                      type: 'PATCH',
                      data : $(this).serialize(),
                      success: function(){
                        $("#update_motivation_level_modal").modal("hide");
                        self.dataTable.draw(false);
                      }
                    });    
                })
               
                $(".btn-motivation").on("click", function() {
                    console.log("clicked");
                    $(".btn-motivation").removeClass("btn-motivation-selected");
                    $(this).addClass("btn-motivation-selected");
                    $("#prospect_motivation_level").val($(this).data("motivation_level"));
                });
            });
         });

         $(".called-back-btn").bind('ajax:complete', function() {
            console.log("popopo");
            self.dataTable.draw(false);
         });
    }

    onDataChanged(data) {
    }

    onDestroy() {

        $("#graph_clients").attr("style", "").prev().remove();
        $("#graph_contact").attr("style", "").prev().remove();
        $("#graph_code").attr("style", "").prev().remove();
        $("#graph_driving").attr("style", "").prev().remove();

        this.dataTable.destroy();
        this.prospect_type.selectpicker('destroy');
        this.prospect_formula.selectpicker('destroy');
        this.dataTable = null;
    }
}