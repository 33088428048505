export default class DrivingCoursesSatisfaction {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        var self = this;

        $(".satisfaction-level-badge").off("click").click(function(e) {
            $("#update_satisfaction_level_modal").find(".modal-title").html("Modifier le niveau de motivation de "+$(this).data("full_name")) 

            $("#update_satisfaction_level_modal").modal();
            $("#update_satisfaction_level_content").html("").load($(this).data("result_url"), function() {
                $("#modal_satisfaction_form").off("submit").submit(function(e) {
                    e.preventDefault();
                    $.ajax({
                      url: $(this).attr('action'),
                      type: 'PATCH',
                      data : $(this).serialize(),
                      success: function(){
                        $("#update_motivation_level_modal").modal("hide");
                        self.dataTable.draw(false);
                      }
                    });    
                })
               
                $(".btn-satisfaction").on("click", function() {
                    $(".btn-satisfaction").removeClass("btn-satisfaction-selected");
                    $(this).addClass("btn-satisfaction-selected");
                    $("#record_satisfaction_feedback_satisfaction_level").val($(this).data("satisfaction_level"));
                });
            });
         });

        $("#period").off("change").change(function(e) {
          if($(this).val()=="custom") {
            $(".custom-period").removeClass("d-none");
          }
          else {
            $(".custom-period").addClass("d-none");
          }
        });

        $(".btn-driving-instructor-feeback").off("click").click(function(e) {
            $("#show_feedback_modal").find(".modal-title").html(""+$(this).data("full_name")) 

            $("#show_feedback_modal").modal();
            $("#show_feedback_modal_content").html("").load($(this).data("feedback_url"));
        });
    }


    onDataChanged(data) {
    }

    onDestroy() {

    }
}