import DefaultDataTable from "../utils/DefaultDataTable";

export default class FormulaCategoriesIndex {

    constructor() {
        this.dataTable = new DefaultDataTable({ "order": [[ 1, "asc" ]], });
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.onDestroy();
    }
}