require("@rails/ujs").start()
require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")
const $ = window.$ = window.jQuery = require("jquery");
window.I18n = require("i18n-js");

import "datatables.net-bs4/css/dataTables.bootstrap4.css"
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css"
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.css"
import "datatables.net-select-bs4/css/select.bootstrap4.css"
import "datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css"

import "dropzone/dist/dropzone.css"
window.Dropzone = require("dropzone/dist/dropzone");

import "popper.js"
require("jquery-toast-plugin")
import "jquery-toast-plugin/dist/jquery.toast.min.css"
require("../backoffice/utils/theme/pages/toastr.init")
import "bootstrap-select/dist/css/bootstrap-select.css"
import "bootstrap"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css"
import "../backoffice/assets/styles/backoffice.scss"

import "../backoffice/utils/NativeConfirm.js"

require("../backoffice/utils/jquery.serializeJSON.js")

const select2 = require('select2');
global.select2 = select2;
window.moment = require("moment");

require( 'select2/dist/js/i18n/fr.js');

import Application from "../backoffice/Application";

import "../backoffice/utils/plugins/dropify/dist/js/dropify"
import "../backoffice/utils/plugins/dropify/dist/css/dropify.min.css"

import "leaflet/dist/leaflet.css"
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.min';

import "../backoffice/utils/leaflet_numbered_markers";

import "daterangepicker/daterangepicker.css"
import "daterangepicker/daterangepicker"
import "bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css"
import "bootstrap-colorpicker"
import "clockpicker/dist/bootstrap-clockpicker.css"
require("clockpicker/dist/bootstrap-clockpicker")
import "bootstrap-datepicker"
require("bootstrap-datepicker/js/locales/bootstrap-datepicker.fr.js");


require("bootstrap-select");
require("datatables.net/js/jquery.dataTables")
require("datatables.net-bs4/js/dataTables.bootstrap4.min")
require("datatables.net-responsive/js/dataTables.responsive")
require("datatables.net-responsive-bs4/js/responsive.bootstrap4.min")
require("datatables.net-buttons/js/dataTables.buttons")
require("datatables.net-buttons-bs4/js/buttons.bootstrap4.min")
require("datatables.net-buttons/js/buttons.html5")
require("datatables.net-keytable/js/dataTables.keyTable")
require("datatables.net-select/js/dataTables.select")
require('datatables.net-rowreorder-bs4')
//require("pdfmake/build/pdfmake")
//require("pdfmake/build/vfs_fonts")
require('jquery-bootstrap-wizard')
require('jquery-validation')

import "jquery-knob/dist/jquery.knob.min"

import PrettyAlert from '../backoffice/utils/PrettyAlert';
window.PrettyAlert = PrettyAlert;

// import ApexCharts from 'apexcharts';
// window.ApexCharts = ApexCharts;

window.application = new Application();

require("../backoffice/utils/theme/vendor");

Turbolinks.scroll = {};
$(document).on('turbolinks:load', function(event) {
    require("../backoffice/utils/theme/pages/form-pickers.init");
    require('jquery-mask-plugin')

    console.log("scroll state on load => "+window.localStorage.scroll_top);

    const elements = document.querySelectorAll("[data-turbolinks-scroll]");
  
      elements.forEach(function(element){
        
        element.addEventListener("click", ()=> {
          window.localStorage.scroll_top = document.scrollingElement.scrollTop;
          console.log("click top -> "+window.localStorage.scroll_top);
        });
        
        element.addEventListener("submit", ()=> {
          window.localStorage.scroll_top = document.scrollingElement.scrollTop;
          console.log("submit top -> "+window.localStorage.scroll_top);
        });
        
      });
      
      if (window.localStorage.scroll_top) {
        document.scrollingElement.scrollTo(0, window.localStorage.scroll_top);
      }
      
      window.localStorage.scroll_top = null;

    $(document).on("submit", "form[method=get]", function(e) {
        Turbolinks.visit(this.action+(this.action.indexOf('?') === -1 ? '?' : '&')+$(this).serialize());
        return false;
    });

    Turbolinks.setProgressBarDelay(500);

    let $body = $("body");
    application.setController($body.data("controller"));
    application.setAction($body.data("action"));
    application.setContext($body.data("context"));
    application.pageChanged();

    require("../backoffice/utils/theme/app");
    $.App.init();
    Waves.init();
});