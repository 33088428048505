export default class DrivingTestsNew {

    constructor() {
        this.citiesHash = {}
        this.placesHash = {}
        this.validation = null;
        this.init_validation();
        this.bindEvents();

        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        var self = this;
        $('#driving_test_city_id').change( this.city_changed );
    }

    onDataChanged(data) {
        this.citiesHash = data.cities_hash;
        this.placesHash = data.places_hash;
    }

    onDestroy() {
        if(this.validation !== undefined){
            this.validation.destroy();
        }
    }

    city_changed(e) {
        var self = application.current_handler;
        let $el = $(e.currentTarget);
        let val = $el.val();

        $("#driving_test_departure_place_id").html("");

        if(![null, undefined, ""].includes(val)){

            let selected_city_id = parseInt($el.val());

            if(self.citiesHash[selected_city_id]!=null) {
                $("<option></option>").attr("value", '').text("Choisissez un point de départ").appendTo("#driving_test_departure_place_id");
                $.each(self.citiesHash[selected_city_id].departure_places, function(idx, place) {
                    if(place.city_id === selected_city_id) {
                        $("<option></option>").attr("value", place.id).html(place.name).appendTo("#driving_test_departure_place_id");
                    }
                });
            }

            $("#driving_test_departure_place_id").trigger("change");


            let ajaxData = {
                url: '/driving_tests/'+$el.val()+'/get_instructors'
            }

            $.ajax(ajaxData).done(function(instructors) {
                let instructors_select = $('#driving_test_driving_instructor_id');
                instructors_select.empty();
                instructors_select
                    .append(
                        $("<option></option>")
                            .attr("value", '')
                            .text("Choisissez un moniteur")
                    );
                $.each(instructors, function(key, instructor) {
                    console.log(key);
                    instructors_select
                        .append(
                            $("<option></option>")
                                .attr("value", instructor.id)
                                .text(instructor.name)
                                .data('city-id', instructor.city_id)
                                .data('selected', key === 0)
                        );
                });
                instructors_select.select2('destroy').select2();
            });
        }else{
            $('#driving_test_driving_instructor_id').empty().append(
                $("<option></option>")
                    .text("Choisissez un moniteur")
            ).select2('destroy').select2();
        }
    }

    init_validation() {
        let validation_options = {
            rules: this.validate_rules(),
            errorElement : 'div',
            errorLabelContainer: '.errorTxt',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation = $("#driving_test_form").validate(validation_options);
    }

    validate_rules() {
        return {
            "driving_test[driving_transmission_type]":{
                required: true
            },
            "driving_test[slot_count]":{
                required: true
            },
            "driving_test[place_name]":{
                required: true
            },
            "driving_test[departure_place_id]":{
                required: true
            },
            "driving_test[driving_instructor_id]":{
                required: true
            },
            "driving_test[departure_date]":{
                required: true,
                step: false
            },
            "driving_test[real_date]":{
                required: true,
                step: false
            },
        }
    }
}