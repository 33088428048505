export default class DrivingCoursesIndex {
  constructor() {

      this.search_options = null;
      this.date_picker = null;
      this.current_month = null;

      window.application.setOnDataChangeListener(this);

      this.bindEvents();
  }


  bindEvents() {
    var self = this;
    $(".reset_filters").click(function(e) {
      console.log("empty localStorage");
      window.localStorage["driving_courses_search_form"] = "{}";
      var date = $('input[id="month_select"]')
      Turbolinks.visit(location.protocol + '//' + location.host + location.pathname+ "?date="+self.current_month);
    });


    $('a[aria-label="next"]').click(function (){
      $(this).addClass("disabled");
    });

    $('a[aria-label="prev"]').click(function (){
      $(this).addClass("disabled");
    });

   $('.edit_driving_course_modal').off("click").on('click', function (e) {
      let loadurl = $(this).data("href");
      let $modal = $("#edit_driving_course_modal");
       $modal.modal();
       $modal.find('.modal-body').load(loadurl);
    });

    this.date_picker = $('input[id="month_select"]').datepicker({
        language: 'fr',
        format: "MM yyyy",
        maxViewMode: 2,
        minViewMode:1,
        autoclose: true
    }).on('changeDate', function(e) {

        e.date.setHours( e.date.getHours() + 2 );

        let params = ""

      params = "?date=" + e.date.getUTCFullYear() +"-"+ (e.date.getUTCMonth() + 1) +
               "&city_id=" + $("[name=city_id]").val() +
               "&category_id=" + $("[name=category_id]").val() +
               "&search_text=" + $("[name=search_text]").val() +
               "&is_cpf=" + ($("[name=is_cpf]").prop("checked") ? 'true' : "");


        $("[name='search_options[]']").val().forEach(function (item){
            params += "&search_options%5B%5D=" + item;
        })

        Turbolinks.visit(
            location.protocol + '//' + location.host + location.pathname + params

        );
    });

    this.search_options = $('#search_options').selectpicker({noneSelectedText: "Options de recherche"});
  }

  onEditFormloaded() {
    console.log("onEditFormloaded() called");

    $("#record_highway_code_status_status_has_highway_code, #record_highway_code_status_status_no_highway_code").on("change", function(e) {
        if($("#record_highway_code_status_status_has_highway_code").prop("checked")==true) {
            $(".highway_code_obtained_at_form_group").removeClass("d-none");
            $("#highway_code_obtained_at").prop("required", true);
        }
        else {
             $(".highway_code_obtained_at_form_group").addClass("d-none");
            $("#highway_code_obtained_at").prop("required", false).val("");
            $("#highway_code_obtained_at_hint").addClass("d-none");
        }
    });

    $("#record_highway_code_status_status_no_highway_code").trigger("change");

    $("#edit_driving_course_form").off("submit").on("submit", function(e) {
        e.preventDefault();
        console.log($(this).serializeJSON());
        let ajaxData = {
            url: $(this).attr("action"),
            data : $(this).serializeJSON(),
            method: "PUT"
        };

        $.ajax(ajaxData).done(function(json) {
            application.successNotification(json['message']);
            $("#edit_driving_course_modal").modal('hide');
        }).fail(function (error){
            application.errorNotification(error.responseJSON['message']);
        });
    });
  }

  onDataChanged(data) {
    console.log("data");
    console.log(data);
    this.current_month = data.current_month;
    if(data.search_params!=null) {
      window.localStorage["driving_courses_search_form"] = JSON.stringify(data.search_params);
    }
  }

  onDestroy() {
      this.search_options.selectpicker('destroy');
  }
}