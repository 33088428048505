export default class DrivingInstructorsForm {

    constructor() {
        this.driving_instructor_driving_transmission_type = null;
        this.driving_instructor_driving_fuel_type = null;
        this.driving_instructor_city_id = null;
        this.dropify = null;
        this.dropify_upload = null;
        this.phone_mask = null;

        this.init();

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    init(){
        this.phone_mask = $("#driving_instructor_phone_number").mask('00 00 00 00 00');
        this.dropify = $('#profile_image').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });
        this.dropify_upload = $('#driving_instructor_given_documents').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });

        this.driving_instructor_driving_transmission_type = $('#driving_instructor_driving_transmission_type').selectpicker();
        this.driving_instructor_driving_fuel_type = $('#driving_instructor_driving_fuel_type').selectpicker();
        this.driving_instructor_city_id = $('#driving_instructor_city_id').selectpicker();
    }

    bindEvents() {
        $("#see_planing").off("click").on("click", this.showPlaning)

        $(".copy-monday-btn").off("click").on("click", this.onCopyMondayClicked);

        $("#driving_instructor_city_id").off("change").on("change", function() {
            $("#driving_instructor_departure_place_id").html("");

            $.each($("#driving_instructor_departure_place_id").data("departure_places")[$(this).val()], function(idx, data) {
                $("<option>").attr("value", data[1]).html(data[0]).appendTo("#driving_instructor_departure_place_id");
            });
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.driving_instructor_driving_transmission_type.selectpicker('destroy');
        this.driving_instructor_driving_fuel_type.selectpicker('destroy');
        this.driving_instructor_city_id.selectpicker('destroy');
        this.phone_mask.unmask();
    }

    showPlaning(){
        $('#planing_settings').tab('show');
        $('#planing').tab('show');
    }


    onCopyMondayClicked(e) {
        e.preventDefault();
        var day = $(e.target).data("day");
        var mondayStart = $("#working_hour_schedule_monday_starts_at").val();
        var mondayEnd = $("#working_hour_schedule_monday_ends_at").val();

        $("#working_hour_schedule_"+day+"_starts_at").val(mondayStart);
        $("#working_hour_schedule_"+day+"_ends_at").val(mondayEnd);

        $("#working_hour_schedule_"+day+"_starts_at").trigger("change");
        $("#working_hour_schedule_"+day+"_ends_at").trigger("change");
    }
}