export default class FormulaCategoriesForm {

    constructor() {
        this.dropify = null;
        this.id = null;
        this.init();
        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }

    bindEvents() {

        const self = this;

        this.dropify.on('dropify.afterClear', function(event, element){

            if(self.id != null){
                var ajaxData = {
                    url: "/formula_categories/delete_image/" + self.id,
                    method: "DELETE"
                };

                $.ajax(ajaxData).done(function(json) {
                    $("#update_event_btn").prop("disabled", false);
                    if(json.success) {
                        PrettyAlert.clear("#edit_event_form");
                        $("#edit_event_modal").modal("hide");
                        self.updateEventCalendar(json.event);
                    }
                    else {
                        PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer."});
                    }
                }).fail(function(err) {
                    PrettyAlert.showError({ selector: "#edit_event_form", message: "Une erreur est survenue, veuillez réessayer."});
                    $("#update_event_btn").prop("disabled", false);
                });
            }
        });
    }

    onDataChanged(data) {
        if(data.id != null){
            this.id = data.id
        }
    }

    onDestroy() {
    }

    init() {
        this.dropify = $('#image').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });
    }
}