export default class PrettyAlert {

  static showError(opts) {
    var prettyAlert = $("<div>");
    prettyAlert.prop("class", "alert alert-danger");
    var msg = '<button type="button" class="close" data-dismiss="alert">&times;</button>'
    msg += opts.message;
    prettyAlert.html(msg);
    $(opts.selector).prepend(prettyAlert);
  }

  static showWarning(opts) {
    var prettyAlert = $("<div>");
    prettyAlert.prop("class", "alert alert-warning");
    var msg = '<button type="button" class="close" data-dismiss="alert">&times;</button>'
    msg += opts.message;
    prettyAlert.html(msg);
    $(opts.selector).prepend(prettyAlert);
  }

  static showWarningNoClose(opts) {
    var prettyAlert = $("<div>");
    prettyAlert.prop("class", "alert alert-warning");

    msg = opts.message;
    prettyAlert.html(msg);
    $(opts.selector).prepend(prettyAlert);
  }

  static showInformation(opts) {
    var prettyAlert = $("<div>");
    prettyAlert.prop("class", "alert alert-info");
    var msg = '<button type="button" class="close" data-dismiss="alert">&times;</button>'
    msg += opts.message;
    prettyAlert.html(msg);
    $(opts.selector).prepend(prettyAlert);

  }

  static clear(selector) {
    $(selector).children("div.alert.alert-error, div.alert.alert-warning, div.alert.alert-info").remove();
  }
}
