export default class ExtraDateFuncitions {

    constructor() {
        this.init();
    }

    init() {

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        Date.startOfMonth = function (date = new Date()) {
            return new Date(date.getFullYear(), date.getMonth(), 1);
        }

        Date.prototype.startOfMonth = function () {
            return new Date(this.getFullYear(), this.getMonth(), 1);
        }
    }
}