export default class CitiesForm {

    constructor() {
        this.initElements();
        this.bindEvents();
        this.dropify_upload = null;
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.dropify_upload = $('#city_picture').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    initElements() {

        let $select = $('#city_department_ids')
        $select.select2({
            multiple: true,
            minimumInputLength: 1,
            ajax: {
                contentType: "application/json",
                delay: 250,
                url: $select.data('url'),
                dataType: 'json',
                transport: function (params, success, failure) {
                    params['contentType'] = "application/json";
                    let $request = $.ajax(params);

                    $request.then(success);
                    $request.fail(failure);

                    return $request;
                }
            }
        });
    }
}