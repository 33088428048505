export default class ProspectsNew {

    constructor() {
        this.dropify = null;
        this.validation = null;
        this.wizard = null;
        this.admin_select = null;
        this.source_select = null;
        this.city_select = null;
        this.formula_select = null;

        this.init();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    init(){
        this.phone_mask = $("#prospect_phone").mask('00 00 00 00 00');
        this.dropify = $('#profile_image').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });


        let validation_options = {
            rules: {
                "prospect[last_name]":{
                    required: true
                },
                "prospect[first_name]":{
                    required: true
                },
                "prospect[phone]":{
                    required: true,
                    phone_number_fr: true
                },
                "prospect[email]":{
                    required: true,
                    email: true
                },
                "prospect[address_postal_code]":{
                    required: true
                }
            },
            errorElement : 'div',
            errorLabelContainer: '.errorTxt',
            ignore: [],
            focusInvalid: false,
            invalidHandler: function(form, validator) {

                if (!validator.numberOfInvalids())
                    return;


                $('html, body').animate({
                    scrollTop: $(validator.errorList[0].element).parent().offset().top - 100
                }, 1000);

            }
        }

        if(I18n.locale !== 'en'){
            validation_options.messages = require("jquery-validation/dist/localization/messages_"+I18n.locale);
        }

        this.validation = $("#prospect_form").validate(validation_options);


        this.wizard = $('#rootwizard').bootstrapWizard({
            'tabClass': 'nav nav-pills',
            'onNext': function(tab, navigation, index) {
                return $("#prospect_form").valid();
            },
            'onTabClick': function(tab, navigation, index) {
                if($(tab).attr("id") === "prospect_new_first_tab"){
                    return $("#prospect_form").valid();
                }
            },
        });


        this.admin_select = $('#prospect_admin_id').selectpicker();
        this.source_select = $('#prospect_source_type').selectpicker();
        this.city_select = $('#prospect_city_id').selectpicker();
        this.formula_select = $('#prospect_formula_id').selectpicker();
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.admin_select.selectpicker('destroy');
        this.source_select.selectpicker('destroy');
        this.city_select.selectpicker('destroy');
        this.formula_select.selectpicker('destroy');
        this.dropify.data('dropify').destroy();
        this.validation.destroy();
    }

}