export default class RdvpermisLessonsIndex {

    constructor() {

        this.bindEvents();
    }

    bindEvents() {
        $(".open-rdvpermis-lesson-date-modal").off("click").on("click", function(e) {
            e.preventDefault();
        
            var actionURL = $(this).data("action_url");
        
            $("#edit_lesson_date_form").attr("action", actionURL);
            $("#lesson_date").attr("value", $(this).data("date"))
            $("#edit_lesson_date_modal").modal();
        }); 
    }
}