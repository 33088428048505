export default class ProspectsSessionForm {

    constructor() {
        this.wizard = null;

        this.categories = null;

        this.categoriesHash = {}

        this.formulaHash = {};

        this.citiesHash = {}

        this.placesHash = {}

        this.selected_category_id = $("#record_category_id").val();

        this.init();
        window.application.setOnDataChangeListener(this);
        this.bindEvents();
    }

    init(){


        this.wizard = $('#prospect_session_form_wizard').bootstrapWizard({
            'tabClass': 'nav nav-pills',
            'onNext': function(tab, navigation, index) {
                return $("#record_form").valid();
            },
            'onTabClick': function(tab, navigation, index) {
                if($(tab).attr("id") === "session_form_tab"){
                    return $("#record_form").valid();
                }
            },
        });

        this.wizard.bootstrapWizard('show', 0);
    }

    bindEvents() {
        var self = this;
        $("#record_category_id").unbind("change").change(function(e) {
            console.log("category change called");
            $("#record_formula_id").html("").val("");
            $("#record_session_id").val("");
            $("#record_option_ids").val("");
            $("#record_option_ids").find("option").remove();
            $("#record_option_ids").trigger('change');


            $.each(self.categoriesHash[$(this).val()].formulas, function(idx, formula) {
                $("<option>").attr("value", formula.id).html(formula.label).appendTo("#record_formula_id");
            });

            $.each(self.categoriesHash[$(this).val()].options, function(idx, option) {
                console
                $("<option>").attr("value", option.id).html(option.hr_label).appendTo("#record_option_ids");
                $("#record_option_ids").trigger('change');
            });
        });


        $("#record_city_id").unbind("change").change(function(e) {
            $("#record_departure_place_id").html("");

            let selected_city_id = parseInt($(this).val());

            if(self.citiesHash[selected_city_id]!=null) {
                $.each(self.citiesHash[selected_city_id].departure_places, function(idx, place) {
                    if(place.city_id === selected_city_id) {
                        $("<option>").attr("value", place.ref).html(place.name).appendTo("#record_departure_place_id");
                    }
                });
            }

            $("#record_departure_place_id").trigger("change");
        });


        $("#record_departure_place_id").unbind("change").change(function(e) {
            $("#record_session_id").html("");

            var selectedFormulaId = parseInt($("#record_formula_id").val());
            var selectedCategoryId = parseInt($("#record_category_id").val());

            //console.log('hello');
            //onsole.log(self.placesHash);
            //console.log(self.placesHash.find(place => place.id === parseInt($(this).val())));
            //console.log(self.placesHash.find(place => place.id === parseInt($(this).val())).sessions);
            let category = Object.values(self.categoriesHash).find(f => f.id === selectedCategoryId );
            let formula = Object.values(category.formulas).find(f => f.id === selectedFormulaId );
            let filtered_sessions = [];
            let filtered_city = self.placesHash.find(place => place.ref === $(this).val())
            if(formula.is_online){
                if(filtered_city !== undefined){
                    self.placesHash.map(place => filtered_sessions = filtered_sessions.concat(place.sessions) );
                }
            }else{
                if(filtered_city !== undefined){
                    filtered_sessions = filtered_sessions.concat(filtered_city.sessions)
                }
            }

            console.log("filtered_sessions:");
            console.log(filtered_sessions);
            $.each(filtered_sessions, function(idx, session) {
                console.log(session);
                console.log(selectedFormulaId);
                if(session.formula_id === selectedFormulaId) {
                    $("<option>").attr("value", session.id).html(session.label).appendTo("#record_session_id");
                }
            });

        });


        $("#record_formula_id").unbind("change").change(function(e) {
            $("#record_city_id").trigger("change");

            $("#record_option_ids").val("");
            $("#record_option_ids").find("option").remove();
            $("#record_option_ids").trigger('change');

            self.formulaHash = self.categoriesHash[$("#record_category_id").val()].formulas.find(place => place.id === parseInt($(this).val()))

             $.each(self.categoriesHash[$("#record_category_id").val()].options, function(idx, option) {
                
                $("<option>").attr("value", option.id).html(option.hr_label).appendTo("#record_option_ids");
                $("#record_option_ids").trigger('change');
            });

             $.each(self.formulaHash.active_options, function(idx, option) {
                
                $("<option>").attr("value", option.id).html(option.hr_label).appendTo("#record_option_ids");
                $("#record_option_ids").trigger('change');
            });
        });

        $("#record_payment_type").off("change").on("change", function(e) {
            e.preventDefault();

            if($(this).val()=="payment_check") {
                $(".payment_check-part").removeClass("d-none");
            }
            else {
                $(".payment_check-part").addClass("d-none");
                $("#record_payment_transaction_id").val("");
            }

            if($(this).val()!="payment_cpf") {
                $(".payment-expected-date-part").removeClass("d-none");
            }
            else {
                $(".payment-expected-date-part").addClass("d-none");
                $("#expected_payment_date").val("");
            }
        }).trigger("change");
    }


    onDataChanged(data) {
        this.categories = data.categories;
        this.citiesHash = data.cities_hash;
        this.placesHash = data.places_hash;

        var self = this;

        $.each(this.categories, function(idx, category) {
            self.categoriesHash[category.id] = category;
        });
    }

}