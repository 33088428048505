import BasicDataTable from "../utils/BasicDataTable"

export default class  AccountingFreeLessonReporting {

  constructor() {

    this.data = null;

    this.bindEvents();

  }

  bindEvents() {
    var self = this;

    this.freeLessonsDatatable = new BasicDataTable("#free-lessons-datatable", { export_csv: true, orderable: false, paginate: false });
    
    $("#period").off("change").on("change", function(e) {
      if($(this).val()=="custom") {
        $(".custom-period").removeClass("d-none");
      }
      else {
        $(".custom-period").addClass("d-none");
      }
    });
  }

}