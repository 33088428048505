export default class BackofficeInstructorsBonuses {
  constructor() {
      this.date_picker = null;
      this.dataTable = null;
      this.bindEvents();
  }


  bindEvents() {
    var self = this;
   
    this.date_picker = $('input[id="month_select"]').datepicker({
        language: 'fr',
        format: "MM yyyy",
        maxViewMode: 2,
        minViewMode:1,
        autoclose: true,
        orientation: "bottom",
    }).on('changeDate', function(e) {

        e.date.setHours( e.date.getHours() + 2 );

        let params = ""

      params = "?date=" + e.date.getUTCFullYear() +"-"+ (e.date.getUTCMonth() + 1)
      
      Turbolinks.visit(
          location.protocol + '//' + location.host + location.pathname + params

      );
    });

    this.dataTable = $('#bonus-datatable').DataTable({
      "paging": false,
      "info": false,
      "searching": false,
      "responsive": true,
      "autoWidth": false,
      "language": {
        url: '/data-tables/locales/' + I18n.locale + '.json'
      }
    });
  }
  
  onDataChanged(data) {

  }

  onDestroy() {
    this.date_picker.datepicker('destroy');
    this.dataTable.destroy();
  }
}