export default class DrivingInstructorIndex {

    constructor() {
        this.dataTable = null;

        this.delayTimer = null;
        this.search_instructor_status = null;
        this.search_city = null;
        this.search_car_type = null;
        this.search_contract_type = null;

        this.search_contract_date = null;

        this.timeOut = null;

        $('#ajax-datatable').addClass('show_position');
        this.createElements();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        let self = this;
        $("#search_instructor_status").change(function(){self.dataTable.draw();});
        $("#search_city").change(function(){
            self.dataTable.draw();

            console.log($(this).find("option:selected").val());
            console.log($(this).find("option:selected").val() !== '');
            if($(this).find("option:selected").val() == ''){
                $('#ajax-datatable').addClass('show_position');
            }else{
                $('#ajax-datatable').removeClass('show_position');
            }
        });
        $("#search_car_type").change(function(){self.dataTable.draw();});
        $("#search_contract_type").change(function(){self.dataTable.draw();});
        $("#search_contract_date").change(function(){self.dataTable.draw();});
        $("#search_is_enabled").change(function(){self.dataTable.draw();});
        $("#search_entity_id").change(function(){self.dataTable.draw();});
        $("#search_text").keyup(function(){
            clearTimeout(self.timeOut);
            self.timeOut = setTimeout(function (){
                self.dataTable.draw();
            }, 300);
        });
        this.bindEventButtons();
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.search_instructor_status.selectpicker('destroy');
        this.search_city.selectpicker('destroy');
        this.search_car_type.selectpicker('destroy');

        if ( this.search_contract_type != null ) {
            this.search_contract_type.selectpicker('destroy');
            this.search_entity_id.selectpicker('destroy');
            this.search_contract_date.datepicker('destroy');
        }


        this.dataTable.destroy();
        this.dataTable = null;
    }

    createElements() {
        let self = this;
        this.search_instructor_status = $('#search_instructor_status').selectpicker();
        this.search_city = $('#search_city').selectpicker();
        this.search_car_type = $('#search_car_type').selectpicker();

        var columns = [];

        if(window.application.context=="partner") {
            columns = [
                { "data": "position", "className": "js-position" },
                { "data": "created_at" },
                { "data": "last_name" },
                { "data": "first_name" },
                { "data": "city" },
                { "data": "driving_transmission_type" },
                { "data": "stats" },
                { "data": "actions" },
            ]
        }
        else {
            this.search_contract_type = $('#search_contract_type').selectpicker();
            this.search_entity_id = $("#search_entity_id").selectpicker();
            this.search_contract_date = $('#search_contract_date').datepicker({language: 'fr'});

            columns = [
                { "data": "position", "className": "js-position" },
                { "data": "created_at" },
                { "data": "last_name" },
                { "data": "first_name" }
            ]

            if($("#search_entity_id").length>0) {
                columns.push({ "data": "entity_name" });
            }

            columns.push(
                { "data": "city" },
                { "data": "driving_transmission_type" },
                { "data": "contract_type" },
                { "data": "instructor_type" },
                { "data": "stats" },
                { "data": "actions" }
            );
        }

        this.dataTable = $('#ajax-datatable').DataTable({
            "responsive": true,
            searchDelay: 350,
            "order": window.application.context=="partner" ? [[ 1, "desc" ]]  : [[ 0, "asc" ]],
            "serverSide": true,
            "columnDefs": [{
                "targets": '_all',
                "orderable": false
            }],
            "ajax": {
                'url':"instructors/ajax_index",
                'data': function(data){
                    data.instructor_type = $("#search_instructor_status option:checked").val();
                    data.search_city = $("#search_city option:checked").val();
                    if(window.application.context=="bo") {
                        data.entity_id = $("#search_entity_id option:checked").val();
                        data.search_contract_type = $("#search_contract_type option:checked").val();
                        data.search_contract_date = $("#search_contract_date").datepicker("getDate");
                    }
                    data.driving_transmission_type = $("#search_car_type option:checked").val();
                    
                    data.search_is_enabled = $("#search_is_enabled option:checked").val();
                    data.search["value"] = $("#search_text").val();
                }
            },
            "columns": columns,
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "dom": "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
                self.bindEventButtons();
            },
            createdRow: function( row, data, dataIndex ) {
                if(data.validation_status=="is_draft") {
                    $(row).addClass("bg-warning")
                }
            }
        });
    }

    positionChanged(e) {
        let self = application.current_handler;
        let $item = $(e.target)
        clearTimeout(self.delayTimer);
        self.delayTimer = setTimeout(function() {
            self.updatePosition($item)
        }, 1000);
    }

    updatePosition($el){
        console.log($el);
    }

    bindEventButtons() {
        $('.position_down').unbind('click', this.positionDown).click(this.positionDown)
        $('.position_up').unbind('click', this.positionUp).click(this.positionUp)
    }

    positionUp(e) {
        let $el = $(e.currentTarget);
        let self = application.current_handler;

        $el.parent().parent().fadeOut(250, function() {
            var ajaxData = {
                url: "/instructors/"+$el.data('id')+"/move_up",
                data : {},
                method: "put"
            };
            $.ajax(ajaxData).done(function(json) {
                self.dataTable.draw(false);
            }).fail(function(err) {

            });
        });
    }

    positionDown(e) {
        let $el = $(e.currentTarget);
        let self = application.current_handler

        $el.parent().parent().fadeOut(250, function() {
            var ajaxData = {
                url: "/instructors/"+$el.data('id')+"/move_down",
                data : {},
                method: "put"
            };
            $.ajax(ajaxData).done(function(json) {
                self.dataTable.draw(false);
            }).fail(function(err) {

            });   
        });

        
    }
}