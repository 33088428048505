export default class FormulasForm {

    constructor() {
        this.dropify = null;
        this.formula_select = null;
        this.category_select = null;
        this.driving_select = null;
        this.shifter_select = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        this.dropify = $('#image').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });

        this.formula_select = $('#formula_formula_type').selectpicker();
        this.category_select = $('#formula_category_id').selectpicker();
        this.driving_select = $('#formula_driving_type').selectpicker();
        this.shifter_select = $('#formula_driving_transmission_type').selectpicker();

        if($("#formula_formula_type").val() !== 'driving'){
            $('#col_driving_type').addClass("d-none")
            $('#col_driving_transmission_type').addClass("d-none");
        }

        $("#formula_formula_type").change(function (){
            if($("#formula_formula_type").val() !== 'driving'){
                $('#col_driving_type').addClass("d-none")
                $('#col_driving_transmission_type').addClass("d-none");
                $('#col_included_hours').addClass("d-none");
                $('#formula_included_hours').val(0);
            }else{
                $('#col_driving_type').removeClass("d-none")
                $('#col_driving_transmission_type').removeClass("d-none");
                $('#col_included_hours').removeClass("d-none");
            }
        }).change()

        $('#formula_is_online').change(function (){
            if($(this).prop('checked')){
                $('#formula_is_only_payment').prop('checked', false)
            }
        })

        $('#formula_is_only_payment').change(function (){
            if($(this).prop('checked')){
                $('#formula_is_online').prop('checked', false)
            }
        })
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.formula_select.selectpicker('destroy');
        this.category_select.selectpicker('destroy');
        this.driving_select.selectpicker('destroy');
        this.shifter_select.selectpicker('destroy');
    }

}