export default class InitialAssessmentQuestionsIndex {

    constructor() {
        this.canUpdate = null;
        this.dataTable = null;
        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }


    bindEvents(data) {
    }

    onDataChanged(data) {
        console.log("data => "+data);
        if( data.canUpdate !== undefined ){
            this.canUpdate = data.canUpdate;
        }
        this.initDatatable();
    }

    onDestroy() {
        if ( this.dataTable !== null ) {
            this.dataTable.destroy();
            this.dataTable = null;
        }
    }

    initDatatable(){
        let self = this;

        console.log("this.canUpdate => "+this.canUpdate);
        if(self.canUpdate){
            $("#questions-datatable tbody tr td:first-child").addClass("handle-draggable");
        }

        this.dataTable = $('#questions-datatable').DataTable({
            "responsive": false,
            order: [[0, 'asc']],
            rowReorder: true,
            dom: 'rtp',
            paging: false,
            "columnDefs": [
                {"orderable": false, "targets": 0 },
                {"orderable": false, "targets": 1 },
                {"orderable": false, "targets": 2 },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            }
        });

        this.dataTable.on( 'row-reorder', function ( e, diff, edit ) {

            let successCount = 0;
            let errorCount = 0;

            for ( var i=0, ien=diff.length ; i<ien ; i++ ) {
                let question_id = $(diff[i].node).data("id");
                let question_order = diff[i].newPosition

                $.ajax({
                    url: `questions/${question_id}.json`,
                    contentType: 'application/json',
                    method: "PUT",
                    data: JSON.stringify({
                        initial_assessment_form_question: {
                            position: question_order
                        }
                    }),
                    success: function (){
                        successCount++;
                        if(successCount === diff.length){
                            application.successNotification("success")
                        }
                    },
                    error: function (){
                        errorCount++;
                        if(errorCount > 0 && (errorCount+successCount) === diff.length){
                            application.errorNotification("error")
                        }
                    }
                })
            }
        } );
    }
}