export default class DrivingTestsResults {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        $(".result-checkbox").on("change", function() {
            $(this).closest("form").find(".repassing-max-wait").attr("required",  $(this).val() != "result_passed");
            $(this).closest("form").find(".repassing_max_wait_part").toggleClass("d-none", $(this).val() == "result_passed");
        });

        $("#proof_of_result_file").on("change", function() {
            if ($(this).val()) {
                $("#proof_of_result_selected_file_part").removeClass("d-none");
                $("#proof_of_result_selected_file_part .file-name").text($(this).val().split("\\").pop());
            } else {
                $("#proof_of_result_selected_file_part .file-name").text("");
                $("#proof_of_result_selected_file_part").addClass("d-none");
            }
        });
    }

}