export default class BasicDataTable {

    constructor(node, opts) {
        this.node = node;
        this.dataTable = null;

        this.opts = Object.assign({}, opts);

        this.btns = [];
        if(this.opts!=null && this.opts.export_csv) {
            this.btns.push("csv");
            // this.btns.push({
            //     extend: 'csvHtml5',
            //     text: 'Export CSV',
            //     className: "btn-light",
            //     exportOptions: {
            //         format: {
            //           body: function(html, row, col, node) {
            //             return html.replace("<br/>", "\n").replace("<br>", "\n").replace(/<(?:.|\n)*?>/gm, '');
            //           }
            //         },
            //         modifier: {search: 'applied', order: 'applied'}
            //     }
            // });
        }

        this.bindEvents();
    }


    bindEvents() {

        var columnDefs = [];
        var ordering = false;

        if(this.opts.orderable==null || this.opts.orderable==true) {
            ordering = true;
            columnDefs = [{
                "targets": -1,
                "orderable": false
            }]
        }

        this.dataTable = $(this.node).DataTable({
            lengthChange: false,
            ordering: ordering,
             dom: 'Bfrtip',
            "buttons": this.btns,
            "columnDefs": columnDefs,
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "bPaginate": (this.opts.paginate!=null ? this.opts.paginate : true),
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });

        //this.dataTable.buttons().container().appendTo( $('.col-sm-12.col-md-6:eq(0)', this.dataTable.table().container() ) );
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.dataTable.destroy();
        this.dataTable = null;
    }

}