import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';

import "tinymce/skins/ui/oxide/skin.min.css";

function tinyMce() {    
    tinymce.init({
        base_url: process.env.ASSET_PATH + 'tinymce',
        selector: 'textarea.tinymce',
        plugins:
        'autolink autosave visualblocks link table advlist lists code',
      menubar: '',
      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist preview | forecolor backcolor removeformat | pagebreak | charmap | insertfile image media link anchor | ltr rtl code fullscreen',
      toolbar_sticky: false,
      suffix: '.min',
      promotion: false
    });
}
// if you're using a language file, you can place its content here
export { tinyMce };