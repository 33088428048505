export default class DrivingCoursesCpfSessions {
  constructor() {
      this.bindEvents();
  }


  bindEvents() {
  }

  onDataChanged(data) {
  }

  onDestroy() {
  }


}