export default class PlanificationValidation {
  
    constructor() {
        this.bindEvents();
    }

    bindEvents() {
        var self = this;

        $("#entity_selection").on("change", function(e) {
          console.log("entity changed");
          Turbolinks.visit($(this).find("option:selected").data("url"));
        });


        $("#select_all").off("change").on("change", function(e) {
            $(".session-checkbox").prop("checked", $(this).prop("checked"));
            self.checkSelectAllStatus();
        }); 


        $(".session-checkbox").on("change", function(e) {
           self.checkSelectAllStatus();
        });


        $("#put_selection_on_sale_btn").off("click").on("click", function(e) {
            e.preventDefault();

            $.ajax({
                url: $("#put_selection_on_sale_btn").attr("href"),
                method: "PATCH",
                data: {
                    session_ids: $("#session_validate_form").serializeJSON().session_ids.join(",").split(",")
                }
            })
        });

        $("#put_selection_on_sale_subcontracting_btn").off("click").on("click", function(e) {
            e.preventDefault();

            $.ajax({
                url: $("#put_selection_on_sale_subcontracting_btn").attr("href"),
                method: "PATCH",
                data: {
                    session_ids: $("#session_validate_form").serializeJSON().session_ids.join(",").split(",")
                }
            })
        });

        $("#delete_selection_btn").off("click").on("click", function(e) {
            e.preventDefault();

            $.ajax({
                url: $("#delete_selection_btn").attr("href"),
                method: "DELETE",
                data: {
                    session_ids: $("#session_validate_form").serializeJSON().session_ids.join(",").split(",")
                }
            })
        });

        if($("#congratulation_modal").length>0) {
            $("#congratulation_modal").modal();
        }

    }

    checkSelectAllStatus() {
        if($(".session-checkbox:checked").length>0) {
            $(".selected-sessions-buttons").removeClass("disabled");
        }
        else {
            $(".selected-sessions-buttons").addClass("disabled");
        }
    }
}