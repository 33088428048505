export default class DrivingCoursesEvaluation {

    constructor() {

        this.bindEvents();

    }

    bindEvents() {
        $(".open-evaluation-done-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("actionUrl");

            $("#evaluation_done_form").attr("action", actionURL);
            $("#record_evaluation_done_at").val("");
            $("#record_evaluation_recommended_hours").val("");
            $("#evaluation_done_modal").modal();
        });
    }
}