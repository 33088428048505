export default class ExtraJqValidationFunctions {

    constructor() {
        this.init();
    }

    init(){

        $.validator.addMethod("phone_number_fr", function( value, element ) {
            let val = value.replace(/ /g, '');
            return val.match(/[0-9]/g) && value.replace(/ /g, '').length === 10;
        }, I18n.t('common.js_validation.phone_number_fr'));

    }
}