export default class SessionNotificationsIndex {

    constructor() {
        this.dataTable = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;


        this.dataTable = $('#ajax-datatable').DataTable({
            "responsive": true,
            searchDelay: 350,
            "order": [[ 1, "desc" ]],
            "serverSide": true,
            pageLength: 100,
            "ajax": {
                'url':"/session_notifications/ajax_index",
                'data': function(data){
                    data.search["value"] = $("#prospect_search").val();
                    data.city_id = $("#prospect_city_id").val();
                }
            },
            "columns": [
                { "data": "is_enabled" },
                { "data": "created_at" },
                { "data": "customer_id" },
                { "data": "email" },
                { "data": "phone" },
                { "data": "driving_formula" },
                { "data": "transmission" },
                { "data": "city_id" },
                //{ "data": "places" },
                { "data": "sent_at" },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "dom": "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
                $('[data-toggle=popover]').popover();
            },
        });

        $('#prospect_search').keyup(function(){self.dataTable.draw();});
        $('#prospect_city_id').change(function(){self.dataTable.draw();});


        this.dataTable.on( 'draw', function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {

        this.dataTable.destroy();
        this.dataTable = null;
    }
}