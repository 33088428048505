import Routes from "./config/Routes";
import ConfigApplication from "../config/Application";
import ConfirmationModal from "./utils/ConfirmationModal";

import { tinyMce } from "../backoffice/utils/tinyMce";

export default class Application extends ConfigApplication{
    constructor() {
        //console.log("Instantiate application");
        super();
        this.routes = new Routes();
        this.confirmationModal = new ConfirmationModal();

        this.loadingTimeout = null;

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
    }
    pageChanged() {
        super.pageChanged();

        this.onAlertChange();
        this.onNoticeChange();
        this.onErrorChange();
        this.onSuccessChange();

        this.updateParametrableLinks();

        tinyMce();


        $(".select2-nosearch").each(function(idx, node) {
            if($(node).next().hasClass('select2-container')) {
              $(node).next().remove();
            }
            $(node).select2({ 
                placeholder: $(node).data("placeholder"),
                minimumResultsForSearch: -1
            });
        });

        $(".select2-with-search").each(function(idx, node) {
            if($(node).next().hasClass('select2-container')) {
              $(node).next().remove();
            }
            $(".select2-with-search").select2({
                placeholder: $(node).data("placeholder"),
                minimumResultsForSearch: 5
            });
        });

        var url = document.location.toString();
        if (url.match('#')) {
            if($('.nav-pills a[href="#'+url.split('#')[1]+'"]').length > 0){
                $('.nav-pills a[href="#'+url.split('#')[1]+'"]').tab('show') ;
            }else{
                $('.nav-tabs a[href="#'+url.split('#')[1]+'"]').tab('show') ;
            }
        } 

        if(window.application.context=="partner") {
            $("#business_notification_call_subject").off("change").on("change", function() {
                if($(this).val()=="partner_subject_other") {
                    $("#other_subject_part").removeClass("d-none");
                }
                else {
                    $("#other_subject_part").addClass("d-none")
                    $("#business_notification_extras_other_subject").val("");
                }
            });

            $('*[data-target="#contact_modal"]').off("click").on("click", function() {
                if($(this).data("selection")!=null) {
                    $("#business_notification_call_subject").val($(this).data("selection"));
                }
            });
        }
    }


    updateParametrableLinks() {
        $.each($(".parametrable-links"), function(idx) {
            try {
                if(window.localStorage[$(this).data("source")] != null) {
                    var _params = JSON.parse(window.localStorage[$(this).data("source")]);
                    var href = $(this).attr("href")+"?";
                    var _keys = Object.keys(_params);
                    var pCount = 0;

                    for(var i=0;i<_keys.length;i++) {
                        if(_params[_keys[i]].length>0) {
                            if(Array.isArray(_params[_keys[i]])) {
                                console.log(_keys[i]+" is an array");
                                for(var j=0;j<_params[_keys[i]].length;j++) {
                                    if(pCount>0) {
                                        href+="&";
                                    }

                                    href += _keys[i]+"[]="+ _params[_keys[i]][j];

                                    pCount+=1;
                                }

                            }
                            else {
                                if(pCount>0) {
                                    href+="&";
                                }
                                href += _keys[i]+"="+ _params[_keys[i]];
                            }
                            pCount+=1;
                        }
                    }

                     $(this).attr("href", href);

                }
            }
            catch(e) {

            }
        });
    }

    bindEventsAfterPageChanged() {
        super.bindEventsAfterPageChanged();

        $('.js-delete-confirm').unbind('click').click(this.delete_confirm)
    }

    onAlertChange(){
        if(window.warning !== null && window.warning !== undefined && window.warning !== '') {
            this.alertNotification(window.warning);
        }
    }

    onNoticeChange(){
        if(window.notice !== null && window.notice !== undefined && window.notice !== '') {
            this.noticeNotification(window.notice);
        }
    }

    onErrorChange(){
        if(window.error !== null && window.error !== undefined && window.error !== '') {
            this.errorNotification(window.error);
        }
    }

    onSuccessChange(){
        if(window.success !== null && window.success !== undefined && window.success !== '') {
            this.successNotification(window.success);
        }
    }

    successNotification(msg){
        $.NotificationApp.send("Succès!",msg,"top-right","#5ba035","success",5000,4)
    }

    errorNotification(msg){
        $.NotificationApp.send("Erreur!",msg,"top-right","#bf441d","error",5000,4)
    }

    noticeNotification(msg){
        $.NotificationApp.send("Information!",msg,"top-right","#3b98b5","info",5000,4)
    }

    alertNotification(msg){
        $.NotificationApp.send("Atention!",msg,"top-right","#da8609","warning",5000,4)
    }

    displayLoading(message, opts) {
      var timeout = ((opts!=null && opts.timeout!=null) ? opts.timeout : 300);
      if(message==null) {
        message = I18n.t("common.loading");
      }
      this.loadingTimeout = window.setTimeout(function () {
        $("#loading_modal").find(".modal-message").html(message);
        $("#loading_modal").modal({ backdrop: "static"});
      }, timeout);

    }

    hideLoading() {
      window.clearTimeout(this.loadingTimeout);
      $("#loading_modal").modal("hide");
    }

    delete_confirm() {
        let self = application
        let title = $(this).data("title");
        let message = $(this).data("message");
        let btnPositive = $(this).data("btn-confirm");
        let btnCancel = $(this).data("btn-cancel");
        let url = $(this).data("url");
        let id = $(this).data("id");
        self.confirmationModal.displayConfirm(
            title,
            message,
            {
                positive_btn: {
                    type: "danger",
                    label: btnPositive,
                    callback: function() {
                        let form = document.createElement('form');
                        form.style.visibility = 'hidden';
                        form.action = url;
                        form.method = 'post'

                        let input = document.createElement('input');
                        input.name = '_method';
                        input.value = 'delete';
                        input.type = 'hidden'
                        form.appendChild(input);

                        let inputToken = document.createElement('input');
                        inputToken.name = 'authenticity_token';
                        inputToken.value = $('meta[name="csrf-token"]').attr('content');
                        inputToken.type = 'hidden'
                        form.appendChild(inputToken);

                        document.body.appendChild(form);
                        form.submit();
                    }
                },
                negative_btn: {
                    label: btnCancel
                }
            });
    }
}