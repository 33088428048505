export default class DrivingTestsCandidates {
    
    constructor() {
        this.bindEvents();
    }

    bindEvents() {

        $('.open-candidate-details-modal').off("click").on('click', function (e) {
            let loadurl = $(this).data("href");
            let $modal = $("#show_candidate_details_modal");
           $modal.modal();
           $modal.find('.modal-content').html("");
           $modal.find('.modal-content').load(loadurl);
        });
    }
}