export default class CodeShow {

    constructor() {
        this.dataTable = null;
        this.range_picker = null;
        this.date_picker = null;

        this.bindEvents();
        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;

        let d = new Date();
        let date_now = new Date(d);
        d.setMonth(d.getMonth() - 1)
        let date_month_ago = new Date(d);

        this.date_picker = $('input[id="session_date"]').datepicker({language: 'fr'});

        this.range_picker = $('input[id="clients_inscrpition_date"]').daterangepicker({
            drops: 'down',
            autoUpdateInput: false,
            locale: {
                cancelLabel: 'Clear'
              }
            //startDate: date_month_ago,
            //endDate: date_now,
        });
        this.range_picker.on("apply.daterangepicker", this.onRangeSelected);

        $('input[id="clients_inscrpition_date"]').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            $("#clients_date_range_starts_at").val("");
            $("#clients_date_range_ends_at").val("");
            application.current_handler.dataTable.draw();
        });
        $('#client_search').keyup(function(){
            if(self.searchFieldTimeout!=null) {
                clearTimeout(self.searchFieldTimeout);
                self.searchFieldTimeout = null;
            }
            self.searchFieldTimeout = setTimeout(function() {
                self.dataTable.draw();
            }, 250);
        });
    }

    onDataChanged(data) {
        if(data !== undefined){
            this.formula_id = data.id;

            this.setTable();
        }
    }

    onDestroy() {

        if(this.dataTable != null){
            this.dataTable.destroy();
            this.dataTable = null;
        }
    }

    onRangeSelected(ev, picker){
        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        $("#clients_date_range_starts_at").val(picker.startDate.toISOString().slice(0, 10));
        $("#clients_date_range_ends_at").val(picker.endDate.toISOString().slice(0, 10));
        application.current_handler.dataTable.draw();
    }

    setTable() {


        this.dataTable = $('#ajax-datatable').DataTable({
            "responsive": true,
            searchDelay: 350,
            "order": [[ 3, "desc" ]],
            "serverSide": true,
            "pageLength": 20,
            "columnDefs": [{
                "targets": -1,
                "orderable": false
            },{
                "targets": -2,
                "orderable": false
            }],
            "ajax": {
                'url':"/code/"+this.formula_id+"/ajax_show",
                'data': function(data){
                    data.clients_date_range_starts_at = $("#clients_date_range_starts_at").val();
                    data.clients_date_range_ends_at = $("#clients_date_range_ends_at").val();
                    data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "last_name" },
                { "data": "first_name" },
                { "data": "email" },
                { "data": "inscription_date" },
                { "data": "session_date" },
                { "data": "actions" },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "dom": "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });

        this.dataTable.on( 'draw', function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }
}