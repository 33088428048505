import DefaultDataTable from "../utils/DefaultDataTable";

export default class ClientsIndex {

    constructor() {
        this.dataTable = null;
        this.client_city = null;
        this.client_formula = null;
        this.range_picker = null;

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;

        $("#graph_clients").knob();
        $("#graph_contact").knob();
        $("#graph_code").knob();
        $("#graph_driving").knob();


        this.dataTable = $('#ajax-datatable').DataTable({
            "responsive": true,
            searchDelay: 350,
            "order": [[ 0, "desc" ]],
            "serverSide": true,
            "pageLength": 20,
            "columnDefs": [{
                "targets": -1,
                "orderable": false
            }],
            "ajax": {
                'url':"clients/ajax_index",
                'data': function(data){
                    data.client_city = $("#client_city option:checked").val();
                    data.client_formula = $("#client_formula option:checked").val();
                    data.clients_date_range_starts_at = $("#clients_date_range_starts_at").val();
                    data.clients_date_range_ends_at = $("#clients_date_range_ends_at").val();
                    data.search["value"] = $("#client_search").val();
                }
            },
            "columns": [
                { "data": "id" },
                { "data": "last_name" },
                { "data": "first_name" },
                { "data": "place" },
                { "data": "driving_instructor_display_name" },
                { "data": "formula_type" },
                { "data": "updated_at" },
                { "data": "actions" },
            ],
            "language": {
                url: '/data-tables/locales/' + I18n.locale + '.json'
            },
            "dom": "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            drawCallback:function(){
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            }
        });

        $('#client_city').change(function(){self.dataTable.draw();});
        $('#client_formula').change(function(){self.dataTable.draw();});
        $('#client_search').keyup(function(){
            if(self.searchFieldTimeout!=null) {
                clearTimeout(self.searchFieldTimeout);
                self.searchFieldTimeout = null;
            }
            self.searchFieldTimeout = setTimeout(function() {
                self.dataTable.draw();
            }, 250);
        });

        this.client_city = $('#client_city').selectpicker();
        this.client_formula = $('#client_formula').selectpicker();

        this.dataTable.on( 'draw', function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        let d = new Date();
        let date_now = new Date(d);
        d.setMonth(d.getMonth() - 1)
        let date_month_ago = new Date(d);

        //$("#clients_date_range_starts_at").val(date_month_ago.toISOString().slice(0, 10));
        //$("#clients_date_range_ends_at").val(date_now.toISOString().slice(0, 10));

        this.range_picker = $('input[id="clients_date_range"]').daterangepicker({
            drops: 'down',
            autoUpdateInput: false,
            locale: {
                cancelLabel: 'Clear'
              }
            //startDate: date_month_ago,
            //endDate: date_now,
        });
        this.range_picker.on("apply.daterangepicker", this.onRangeSelected);

        $('input[id="clients_date_range"]').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            $("#clients_date_range_starts_at").val("");
            $("#clients_date_range_ends_at").val("");
            application.current_handler.dataTable.draw();
        });
    }

    onDataChanged(data) {
    }

    onDestroy() {

        $("#graph_clients").attr("style", "").prev().remove();
        $("#graph_contact").attr("style", "").prev().remove();
        $("#graph_code").attr("style", "").prev().remove();
        $("#graph_driving").attr("style", "").prev().remove();

        this.dataTable.destroy();
        this.client_city.selectpicker('destroy');
        this.client_formula.selectpicker('destroy');
        this.dataTable = null;
    }

    onRangeSelected(ev, picker){
        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        $("#clients_date_range_starts_at").val(picker.startDate.toISOString().slice(0, 10));
        $("#clients_date_range_ends_at").val(picker.endDate.toISOString().slice(0, 10));
        application.current_handler.dataTable.draw();
    }
}