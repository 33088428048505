import BasicDataTable from "../utils/BasicDataTable"

export default class  AccountingFinances {

  constructor() {

    this.data = null;

    this.bindEvents();

    this.refreshObjectivePart();

  }

  bindEvents() {
    var self = this;
    $("#objective-date-selection-form").find('input[name=date]').off("change").on("change", function(e) {
      e.preventDefault();

      self.refreshObjectivePart();

    });

    this.paymentDatatable = new BasicDataTable("#payment-details-datatable", { export_csv: true, paginate: false  });
    this.formulaDatatable = new BasicDataTable("#formula-details-datatable", { export_csv: true, paginate: false  });
    this.refundDatatable = new BasicDataTable("#refund-details-datatable", { export_csv: true, paginate: false  });

    this.subscriptionDatatable = new BasicDataTable("#subscription-datatable", { export_csv: true, orderable: false, paginate: false });
    this.customersDrivingDatatable = new BasicDataTable("#customers-driving-datatable", { export_csv: true, orderable: false, paginate: false  });
    this.customersCodeDatatable = new BasicDataTable("#customers-code-datatable", { export_csv: true, orderable: false, paginate: false  });
    this.customersAHDatatable = new BasicDataTable("#customers-additional-hours-datatable", { export_csv: true, orderable: false , paginate: false });


    $("#period").off("change").on("change", function(e) {
      if($(this).val()=="custom") {
        $(".custom-period").removeClass("d-none");
      }
      else {
        $(".custom-period").addClass("d-none");
      }
    });
  }

  refreshObjectivePart() {
    var self = this;
    var dateField = $("#objective-date-selection-form").find('input[name=date]');
    $("#objective_part").html("");
    $("#objective_part").load($("#objective-date-selection-form").attr("action")+"?date="+$(dateField).val(), function() {
        self.bindSalesTargetForm();
      });
  }

  bindSalesTargetForm() {
    $("#sales_target_form").off("submit").on("submit", function() {
       $.ajax({
        url: $(this).attr('action'),
        type: 'POST',
        data : $(this).serialize(),
        success: function(json){
          if(json.success) {
            $("#objective_updated_alert").removeClass("d-none")
          }
        }
      });
      return false;
    });
  }

}