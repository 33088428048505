export default class CpfIndex {

    constructor() {

        this.bindEvents();
    }

    bindEvents() {
        $('#validation_modal').on('show.bs.modal', function (event) {
            let button = $(event.relatedTarget)
            let id = button.data('item-id')
            let name = button.data('name')
            let amount = button.data('amount')
            let action = button.data('href')
            let paymentDate = button.data('date')

            $("#customer_name").text(name);
            $("#validation_date").val(paymentDate);
            $("#customer_payment_amount").text(amount);
            $("#back_url").val(window.location.href);
            $("#validation_form").attr("action", action);
        })

        $("#classify_modal").on('show.bs.modal', function (event) {
            let button = $(event.relatedTarget)
            let id = button.data('item-id')
            let name = button.data('name')
            let amount = button.data('amount')
            let action = button.data('href')
            let paymentDate = button.data('date')

            $("#classify_customer_name").text(name);
            if(paymentDate!=null) {
                console.log(paymentDate);
                $("#cpf_theoric_payment_at").val(paymentDate);
            }
            $("#classify_customer_payment_amount").text(amount);
            $("#classify_back_url").val(window.location.href);
            $("#classify_form").attr("action", action);
        })
    }
}