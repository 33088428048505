export default class ProspectsMap {

    constructor() {
        this.initial_latitude = $("#cover_map").data("latitude");
        this.initial_longitude = $("#cover_map").data("longitude");
        this.departure_places = $("#cover_map").data("departure_places");
        this.initMap();
    }

    initMap() {
        var self = this;
        $('#cover_map').height(500);
        this.map = L.map('cover_map').setView([this.initial_latitude,this.initial_longitude], 15);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxNativeZoom: 19, maxZoom: 23,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        var bounds = L.latLngBounds();

        $.each(this.departure_places, function(idx, dp) {
            bounds.extend([dp.latitude, dp.longitude]);
            L.marker([dp.latitude, dp.longitude], {
                icon:   new L.NumberedDivIcon({number: dp.active_driving_instructor_count})
            }).addTo(self.map)
            .bindPopup("<b>"+dp.name+"</b><br/>"+(dp.is_lanavette? "Point de départ La Navette" : "Point de départ partenaire ("+dp.partner_name+")"));
        });

        if(bounds.size) {
            this.map.fitBounds(bounds);
        }

    }
}