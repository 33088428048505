export default class BackofficeSupplyRequestsIndex {
  constructor() {
      this.date_picker = null;
      this.dataTable = null;
      this.bindEvents();
  }


  bindEvents() {
    var self = this;
   
    this.date_picker = $('input[id="month_select"]').datepicker({
        language: 'fr',
        format: "MM yyyy",
        maxViewMode: 2,
        minViewMode:1,
        autoclose: true,
        orientation: "bottom",
    }).on('changeDate', function(e) {

        e.date.setHours( e.date.getHours() + 2 );

        let params = ""

      params = "?date=" + e.date.getUTCFullYear() +"-"+ (e.date.getUTCMonth() + 1)
      
      Turbolinks.visit(
          location.protocol + '//' + location.host + location.pathname + params

      );
    });

    this.dataTable = $('#supply-requests-datatable').DataTable({
      "paging": true,
      "info": true,
      "searching": true,
      "responsive": true,
      "autoWidth": false,
      "order": [[ 0, "desc" ]],
      "language": {
        url: '/data-tables/locales/' + I18n.locale + '.json'
      }
    });

    $(".launch-modal").on("click", function(e) {
      var url = $(e.currentTarget).data('url');
      self.launchSypplyRequestModal(url);
    }.bind(self));

    $("#submit-supply-request").on("click", function(e) {
      var form = $('#supply-request-form');
      var url = form.attr('action');
      var data = form.serialize();

      $.ajax({
        url: url,
        type: 'POST',
        data: data
      });
    });
  }
  
  launchSypplyRequestModal(modal_url) {
    var self = this;
    $.ajax({
      url: modal_url,
      type: 'GET',
      success: function(data) {
        $('#modal-form').html(data);
        $('#supplyRequestModal').modal('show');
      }
    });
  }

  onDataChanged(data) {

  }

  onDestroy() {
    this.date_picker.datepicker('destroy');
    this.dataTable.destroy();
  }
}